import { Checkbox, Col, Form, message, Modal, notification, Row, Select } from 'antd';
import { Store, StoreValue } from 'antd/lib/form/interface';
import { formatISO, isValid } from 'date-fns';
import { parseISO } from 'date-fns/fp';
import { AuthorStatus, BlockType, PostStatus, PostType, Section, SectionStatus } from 'pn-backend';
import { stringify } from 'query-string';
import {
  __,
  allPass,
  always,
  anyPass,
  applySpec,
  assoc,
  equals,
  evolve,
  find,
  identity,
  ifElse,
  includes,
  insert,
  isNil,
  map,
  pipe,
  prop,
  propEq,
  reject,
  slice,
  unapply,
  uniqBy,
} from 'ramda';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { Button, DatePicker, Visible, ZodErrors } from '~components';
import { PublicRoutesPath } from '~constants';
import { useHasPermission, usePost } from '~hooks';
import { ConfigContext } from '~providers';
import { blocksEntitySelector, hasPendingBlocksSelector, userSelector } from '~selectors';
import {
  useCreatePostPreviewTokenMutation,
  useDeletePostMutation,
  useGetAuthorsListQuery,
  useGetFeedsListQuery,
  useGetSectionsListQuery,
  useGetTagsListQuery,
  usePublishPostMutation,
  useUnpublishPostMutation,
  useUpdatePostMutation,
} from '~services';
import { validationActions } from '~slices';
import {
  getBlockDocumentId,
  getTypeTitle,
  isArticle,
  isCards,
  isCustom,
  isDefined,
  isLongread,
  isNewsFull,
  isSpec,
  isSuccessResult,
  isTest,
} from '~utils';
import { postValidationsSchemas, postWarningsSchema } from '~validationSchemas';

import { confirmPostsModal } from '../ConfirmPostModal';

const isSectionVisible = anyPass([
  isArticle,
  isCards,
  isTest,
  isSpec,
  isNewsFull,
  isLongread,
  isCustom,
]);
const isTagVisible = anyPass([isArticle, isNewsFull, isCards, isLongread]);
const isFlagsVisible = anyPass([isArticle, isNewsFull, isCards, isLongread]);
const isRSSVisible = anyPass([isArticle, isNewsFull, isCards, isLongread]);
const isAuthorsVisible = anyPass([isArticle, isNewsFull, isCards, isLongread]);

const postTypeOptions = Object.values(PostType).map(
  applySpec({
    label: getTypeTitle,
    value: identity,
  }),
);

const getInitialValues = evolve({
  authors: map(prop('id')),
  feeds: map(prop('id')),
  publishedAt: ifElse(isNil, always(null), parseISO),
  sections: map(prop('id')),
  tags: map(prop('id')),
});

const getUpdateValues = applySpec({
  authorIds: prop('authors'),
  feedIds: prop('feeds'),
  isAffiliateArticle: prop('isAffiliateArticle'),
  isAuthorVisible: prop('isAuthorVisible'),
  isModificationDateVisible: prop('isModificationDateVisible'),
  sectionIds: prop('sections'),
  tagIds: prop('tags'),
  type: prop('type'),
});

const normalizeValueToArray = pipe<
  [StoreValue, StoreValue, Store],
  StoreValue[],
  StoreValue[],
  StoreValue[]
>(unapply(identity), slice(0, 1), reject(isNil));

const isPostPublished = includes(__, [PostStatus.Published, PostStatus.Postponed]);

const filterSectionByType = (postType: PostType) => pipe(prop('postTypes'), includes(postType));

export const PostSettings: FC = () => {
  const dispatch = useDispatch();
  const post = usePost();
  const { hasUnpublishedChanges, id: postId, status, type } = post;
  const blocks = useSelector(blocksEntitySelector.selectAll);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const hasPermission = useHasPermission();
  const { previewUrl } = useContext(ConfigContext);

  const currentType = Form.useWatch('type', form);
  const formValues = Form.useWatch([], form);
  const publishedTime = Form.useWatch('publishedAt', form);

  const [updatePost] = useUpdatePostMutation();
  const [deletePost] = useDeletePostMutation();
  const [createPostPreviewToken, { isLoading: isCreatingPostPreviewToken }] =
    useCreatePostPreviewTokenMutation();
  const [publishPost, { isLoading: isPublishing }] = usePublishPostMutation();
  const [unpublishPost] = useUnpublishPostMutation();

  const { id: employeeId } = useSelector(userSelector) || {};
  const { data: sections } = useGetSectionsListQuery({}, { skip: !isSectionVisible(currentType) });
  const { data: tags } = useGetTagsListQuery({}, { skip: !isTagVisible(currentType) });
  const { data: feeds } = useGetFeedsListQuery({}, { skip: !isRSSVisible(currentType) });

  const authorsParams = hasPermission ? {} : { employeeId };

  const { data: authors } = useGetAuthorsListQuery(
    { ...authorsParams, relations: [] },
    { skip: !employeeId },
  );
  const initialValues = getInitialValues(post);
  const [value] = useDebounce(formValues, 500);

  const userIsAuthor = equals(post.createdById, employeeId);

  const canPreview = type !== PostType.News && type !== PostType.Spec;
  const isPublished = isPostPublished(status);
  const isSavingBlocks = useSelector(hasPendingBlocksSelector);

  const authorsOptions = useMemo(
    () =>
      uniqBy(prop('id'), [...post.authors, ...(authors?.results || [])]).map((item) =>
        assoc(
          'disabled',
          !hasPermission &&
            item.employeeId !== employeeId &&
            item.status !== AuthorStatus.blocked &&
            !userIsAuthor,
          item,
        ),
      ),
    [authors?.results, employeeId, hasPermission, post.authors, userIsAuthor],
  );

  let sectionsOptions = uniqBy(prop('id'), [
    ...post.sections,
    ...(sections?.results || []).filter(
      allPass([filterSectionByType(post?.type), propEq('status', SectionStatus.published)]),
    ),
  ]);

  const mainSection = find(propEq('alias', 'main'))(sectionsOptions) as Section<string>;

  // TODO временное решение. переписать, когда будет поддержка изменения порядка в справочниках
  if (mainSection) {
    sectionsOptions = pipe(
      reject(propEq('alias', 'main')),
      insert(1, mainSection),
    )(sectionsOptions);
  }

  const tagsOptions = uniqBy(prop('id'), [...post.tags, ...(tags?.results || [])]);

  const feedsOptions = uniqBy(prop('id'), [
    ...post.feeds,
    ...(feeds?.results?.filter(({ postTypes }) => postTypes.includes(type)) || []),
  ]);

  const handlePreview = async () => {
    const result = await createPostPreviewToken({ expiresIn: '1h', id: postId });
    if (isSuccessResult(result)) {
      const { token } = result.data;

      const path =
        post.type === PostType.NewsFull
          ? PublicRoutesPath.NewsPreview
          : PublicRoutesPath.PostPreview;

      window.open(
        `${previewUrl}${generatePath(path, { postId })}?${stringify({
          token,
        })}`,
        '_blank',
      );
    } else {
      message.error('Ошибка получения токена');
    }
  };

  const handleDelete = () => {
    Modal.confirm({
      cancelText: 'Отмена',
      content: 'Отменить данное действие будет невозможно.',
      okText: 'Удалить',
      onOk: async () => {
        await deletePost(postId);
        navigate(-1);
      },
      title: 'Удалить материал?',
    });
  };

  const handlePublish = async () => {
    const postValidationSchema = postValidationsSchemas[type];
    // Перемещаем блок превью в конец, тк он невидимый
    const sortedBlocks = blocks.sort(({ type: typeA }, { type: typeB }) => {
      if (typeA === BlockType.Preview) return 1;
      if (typeB === BlockType.Preview) return -1;
      return 0;
    });
    if (isDefined(postValidationSchema)) {
      const validationResult = postValidationSchema.safeParse({
        blocks: sortedBlocks,
      });
      console.log('validation...', {
        blocks: sortedBlocks,
        validationResult,
      });
      const errors = validationResult.success
        ? []
        : validationResult.error.issues.map((issue) => {
            const [, index, contentType] = issue.path;

            let errorBlockId = blocks[+index].id;

            if (contentType === 'blocks') {
              const [, , , secondIndex] = issue.path;
              const childBlock = blocks[+index].blocks[+secondIndex];
              if (childBlock) {
                errorBlockId = childBlock.id;
              }
            }
            return {
              ...issue,
              blockId: errorBlockId,
            };
          });

      dispatch(
        validationActions.setErrors({
          errors,
        }),
      );

      if (errors.length > 0) {
        const [firstError] = errors;
        const element = document.getElementById(getBlockDocumentId(firstError.blockId));

        if (isDefined(element)) {
          element.scrollIntoView(true);
        }
      }

      if (!validationResult.success) {
        notification.error({
          description: <ZodErrors errors={validationResult?.error.flatten()} />,
          duration: 10,
          message: `Ошибка валидации`,
          placement: 'bottomRight',
        });
        return;
      }
    }

    const warningsResult = postWarningsSchema.safeParse(post);
    console.log('warnings...', { post, warningsResult });

    if (!warningsResult.success) {
      const messages = warningsResult?.error?.issues.map(prop('message')) || [];

      if (!(await confirmPostsModal({ messages }))) {
        return;
      }
    }

    publishPost({
      id: post?.id,
      publishedAt: formatISO(isValid(publishedTime) ? publishedTime : new Date()),
    });
  };

  const handleUnpublishPost = async () => {
    return unpublishPost(postId);
  };

  const onCommit = handlePublish;

  useEffect(() => {
    if (form.isFieldsTouched()) {
      updatePost({ ...getUpdateValues(value), id: postId });
    }
  }, [form, postId, updatePost, value]);

  useEffect(() => {
    if (post?.publishedAt) {
      form.setFieldValue('publishedAt', parseISO(post?.publishedAt));
    }
  }, [form, post?.publishedAt]);

  return (
    <Row gutter={[0, 25]}>
      <Col span={24}>
        <Form
          form={form}
          layout="inline"
          initialValues={initialValues}
          style={{ marginRight: '-15px' }}
        >
          <Row gutter={[0, 15]}>
            <Col xs={24} md={8}>
              <Form.Item name="type" label="Тип публикации" labelCol={{ span: 24 }}>
                <Select size="large" options={postTypeOptions} placeholder="Выбрать" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item name="publishedAt" label="Дата публикации" labelCol={{ span: 24 }}>
                <DatePicker
                  disabled={isPublished}
                  style={{ width: '100%' }}
                  size="large"
                  showNow
                  showTime
                  format="dd.MM.yyyy, HH:mm"
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Visible isVisible={isSectionVisible(currentType)}>
              <Col xs={24} md={8}>
                <Form.Item name="sections" label="Раздел" labelCol={{ span: 24 }}>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      isDefined(option) && option.title.toLowerCase().includes(input.toLowerCase())
                    }
                    size="large"
                    placeholder="Выбрать"
                    options={sectionsOptions}
                    mode="multiple"
                    maxTagCount="responsive"
                    fieldNames={{ label: 'title', value: 'id' }}
                  />
                </Form.Item>
              </Col>
            </Visible>

            <Visible isVisible={isAuthorsVisible(currentType)}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="authors"
                  label="Автор"
                  labelCol={{ span: 24 }}
                  normalize={normalizeValueToArray}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      isDefined(option) && option.name.toLowerCase().includes(input.toLowerCase())
                    }
                    allowClear
                    size="large"
                    placeholder="Выбрать"
                    options={authorsOptions}
                    maxTagCount="responsive"
                    fieldNames={{ label: 'name', value: 'id' }}
                  />
                </Form.Item>
              </Col>
            </Visible>

            <Visible isVisible={isTagVisible(currentType)}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="tags"
                  label="Тег"
                  labelCol={{ span: 24 }}
                  normalize={normalizeValueToArray}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      isDefined(option) && option.name.toLowerCase().includes(input.toLowerCase())
                    }
                    allowClear
                    size="large"
                    placeholder="Выбрать"
                    options={tagsOptions}
                    maxTagCount="responsive"
                    fieldNames={{ label: 'name', value: 'id' }}
                  />
                </Form.Item>
              </Col>
            </Visible>

            <Visible isVisible={isRSSVisible(currentType)}>
              <Col xs={24} md={8}>
                <Form.Item name="feeds" label="RSS" labelCol={{ span: 24 }}>
                  <Select
                    mode="multiple"
                    size="large"
                    filterOption={(input, option) =>
                      isDefined(option) && option.name.toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder="Выбрать"
                    maxTagCount="responsive"
                    allowClear
                    options={feedsOptions}
                    fieldNames={{ label: 'name', value: 'id' }}
                  />
                </Form.Item>
              </Col>
            </Visible>
            <Visible isVisible={isFlagsVisible(currentType)}>
              <Col span={24}>
                <Form.Item>
                  <Row gutter={[15, 15]}>
                    <Col>
                      <Form.Item name="isAuthorVisible" valuePropName="checked" noStyle>
                        <Checkbox>Отображать автора</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item name="isModificationDateVisible" valuePropName="checked" noStyle>
                        <Checkbox>Отображать дату изменения</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item name="isAffiliateArticle" valuePropName="checked" noStyle>
                        <Checkbox>Партнерский материал</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Visible>
          </Row>
        </Form>
      </Col>
      <Col span={24}>
        <Row gutter={[10, 10]} justify="space-between">
          <Col xs={24} md={12}>
            <Row gutter={[10, 10]}>
              <Visible isVisible={isPublished}>
                <Col xs={24} md={12}>
                  <Button size="large" type="ghost" onClick={handleUnpublishPost}>
                    СНЯТЬ С ПУБЛИКАЦИИ
                  </Button>
                </Col>
              </Visible>
              <Col xs={24} md={12}>
                <Button size="large" type="ghost" onClick={handleDelete}>
                  УДАЛИТЬ МАТЕРИАЛ
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row gutter={[10, 10]} justify={{ md: 'end', xs: 'start' }}>
              <Visible isVisible={canPreview}>
                <Col>
                  <Button
                    size="large"
                    type="default"
                    loading={isCreatingPostPreviewToken}
                    onClick={handlePreview}
                  >
                    ПРЕДПРОСМОТР
                  </Button>
                </Col>
              </Visible>
              <Visible isVisible={isPublished}>
                <Col>
                  <Button
                    size="large"
                    type="primary"
                    disabled={!hasUnpublishedChanges || isSavingBlocks}
                    loading={isPublishing}
                    onClick={onCommit}
                  >
                    ПРИМЕНИТЬ
                  </Button>
                </Col>
              </Visible>
              <Visible isVisible={!isPublished}>
                <Col>
                  <Button
                    size="large"
                    type="primary"
                    disabled={isSavingBlocks}
                    loading={isPublishing}
                    onClick={handlePublish}
                  >
                    ОПУБЛИКОВАТЬ
                  </Button>
                </Col>
              </Visible>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
